.course-release-heading {
    font-weight: bold;
    font-size: 16px;
}

.course-release-to-list {
    font-size: 12px;
}

textarea.ant-input.course-release-modal-email {
    width: 100%;
    height: 400px;
}
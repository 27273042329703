.auditlogfilters-container {
  margin: 8px 0 16px 0;
  max-width: 850px;
}

.auditlogfilters-container .ant-row {
  padding: 4px 0;
}

.auditlogs-searchinput {
  width: 100%;
  max-width: 500px;
}

.auditlogfilters-otherfilters .ant-col:not(:last-child) {
  padding-right: 4px;
}

.auditlogfilters-fields {
  width: 100%;
  max-width: 500px;
}
.select-sections-search {
    width: 100%;
}

.select-sections-search-list .ant-list-item-meta-description *:not(:last-child) {
    margin-right: 1em;
}

.select-sections-search-list .ant-list-item {
    cursor: pointer;
}

.select-sections-search-list .ant-list-item:hover {
    background-color: rgba(98,140,255,0.08);
}

.select-sections-search-list label.ant-checkbox-wrapper {
    margin-right: 16px;
}

.select-sections-search-heading {
    font-weight: bold;
    font-size: 16px;
}
.summarypagefilter-container {
    margin: 8px 0 16px 0;
    max-width: 850px;
    display: flex;
    justify-content: space-between;
    align-items: center;    
  }

  .summarypagefilter-container .ant-row {
    padding: 4px 0;
  }

.summarypagefilter-yearselect .ant-select-selector {
    /*min-width: 120px;*/
    font-size: 22px;
    font-weight: bolder;

    padding-left: 0 !important;  /* TODO using !important isn't great, but this is ignored otherwise. Another solution? */
  }
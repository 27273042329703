.staff-enrolment .staff-enrolment-add-user-page {
    max-width: 818px;
}

.staff-enrolment .staff-enrolment-add-user-field-label {
    font-size: 16px;
    font-weight: 600;    
}

.staff-enrolment .staff-enrolment-add-user-main,
.staff-enrolment .staff-enrolment-add-user-main > *,
.staff-enrolment .staff-enrolment-manage-enrolment-user,
.staff-enrolment .staff-enrolment-manage-enrolment-user > * {
    width: 100%;
}

.staff-enrolment .staff-enrolment-staff-search-field {
    width: 450px;
}
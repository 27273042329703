.termcard {
    font-family: Montserrat, 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    background-color: white;
    line-height: 1.4;
    border-left-width: 4px;
    min-height: 50px;
    max-width: 850px;
    padding: 0;
    margin: 0 0 21px 0;
    box-shadow: 0 2px 7px 1px rgba(0,0,0,0.09);
  }
  .termcard h1 {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
  }
  .termcard-titlebar {
    height: 45px;
    box-shadow: 0 1px 0 0 rgba(0,0,0,0.1);
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }

  .termcard-titlebar.collapsed {
    box-shadow: unset;
  }
  .termcard-titlecontainer {
    display: inline-block;
  }
  .termcard-titlebar-horizontalspace {
    justify-content: space-between;
    align-content: center;
    flex-direction: row;
    display: flex;
  }
  .termcard-body {
    padding: 16px;
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1);
  }
  .termcard-bottombar {
    padding: 0 16px 0 16px;
    background-color: #f0f2f5;
    height: 45px;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1em;
  }

  .termcard-expandbutton {
    display: inline-block;
  }

  .termcard-display {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 1fr auto 3fr auto 1fr;
    grid-template-rows: auto 1fr;
    margin: .5em 1em;
    align-items: center;
  }

  .termcard-coursestats {
    display: grid;
    grid-column: 4 / 5;
    grid-row: 1 / 2;
    grid-template-columns: auto 1fr;
    align-items: center;
    row-gap: .5em;
    column-gap: .25em;
  }

  .termcard-coursestats > div {
    display: contents;
    grid-column: 1 / 3;
  }

  .termcard-coursestats > div > .ant-badge {
    grid-column: 1 / 2;
  }

  .termcard-courseNumber {
    margin: .25em 0;
    text-align: center;
    font-size: 1.5em;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .termcard-coursestats-label {
    margin-left: .5em;
  }

  .termcard-graph-wrapper {
    width: max(200px, 25%);
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
.copy-content-confirm .ant-list-item-meta-description *:not(:last-child) {
    margin-right: 1em;
}

.copy-content-confirm .ant-list-item-meta {
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 0.5em;
    padding: 0.5em;
}

.ant-space.copy-content-confirm {
    display: flex;
    flex-direction: column;
}

.copy-content-confirm-to-label {
    margin-top: 2em;
}
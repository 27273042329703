.courselistfilters-container {
  margin: 8px 0 16px 0;
  max-width: 850px;
}

.courselistfilters-container .ant-row {
  padding: 4px 0;
}

.courselistfilters-yearselect .ant-select .ant-select-selection-item {
  /*min-width: 120px;*/
  font-size: 22px;
  font-weight: bolder;
}

.courselistfilters-termselect .ant-select .ant-select-selection-item {
  font-size: 18px;
  font-weight: bolder;
  /*min-width: 320px;*/
}

.courselistfilters-termselect .ant-select .ant-select-selection-search {
  left: 0 !important;
}

.courselistfilters-yearselect .ant-select-selector {
  padding-left: 0 !important;  /* TODO using !important isn't great, but this is ignored otherwise. Another solution? */
}

.courselistfilters-termselect .ant-select-selector {
  padding-left: 0 !important;  /* TODO using !important isn't great, but this is ignored otherwise. Another solution? */
}

.courselistfilters-otherfilters .ant-select {
  width: 100%;
  /*margin-right: 8px;*/
  /*margin-bottom: 16px;*/
  /*min-width: 250px;*/
}

.courselistfilters-accountselect .ant-select {
  width: 100%;
  /*margin-right: 8px;*/
  /*margin-bottom: 16px;*/
  /*min-width: 250px;*/
}

.courselistfilters-otherfilters .ant-col:not(:last-child) {
  padding-right: 4px;
}

.courselistfilters-sitetypeselect .ant-select {
  width: 100%;
}
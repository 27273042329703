.staff-enrolment .course-name {
    color: #202020;
    /*font-family: Montserrat;*/
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
    display: flex;
    justify-content: space-between;    
}

.staff-enrolment .course-name span {
    margin-right: 10px;
}

.staff-enrolment .ant-modal-body > * {
    width: 100%;
}

.staff-enrolment .ant-alert {
    margin-bottom: 16px;
}

.staff-enrolment {
    width: 100%;
    max-width: 500px;
}
.site-management-site-name {
    width: 100%;
}

.site-management-label {
    margin-top: 20px;
    margin-bottom: 10px;
}

.site-management-card .ant-select {
    width: 350px;
    max-width: 100%;
}

.site-management-label {
    font-weight: bold;
}

.site-management-alert-container {
    width: 100%;
}
.copy-content-search {
    width: 100%;
}

.copy-content-search-input {
    display: flex;
    flex-direction: row;
    margin-top: 1em;
    align-items: center;
}

.copy-content-search-list .ant-list-item-meta-description *:not(:last-child) {
    margin-right: 1em;
}

.copy-content-search-list .ant-list-item {
    cursor: pointer;
}

.copy-content-search-list .ant-list-item:hover {
    background-color: rgba(98,140,255,0.08);
}

.copy-content-search-heading {
    font-weight: bold;
    font-size: 16px;
    margin-right: 8px;
}
.auditlogentry {
  font-family: Montserrat, 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  background-color: white;
  line-height: 1.4;
  border-left-width: 4px;
  min-height: 50px;
  max-width: 850px;
  padding: 0;
  margin: 0 0 21px 0;
  box-shadow: 0 2px 7px 1px rgba(0,0,0,0.09);
}
.auditlogentry h1 {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
}
.auditlogentry-titlebar {
  height: 45px;
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.1);
  padding: 0;
  display: flex;
  font-weight: bold;
  align-content: center;
}

.auditlogentry-titlebar .anticon {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auditlogentry-titlebar h1 {
  display: flex;
  align-items: center;
}

.auditlogentry-summaryarea {
  padding: 8px 16px;
}
.auditlogentry-errorinfo {
  padding: 8px 16px;
  background-color: #ffdede;
}
.auditlogentry-warninginfo {
  padding: 8px 16px;
  background-color: #ffdbab;
}
.auditlogentry-infoinfo {
  padding: 8px 16px;
  background-color: #dee3ff;
}
.auditlogentry-summaryarea .ant-row>div {
  min-height: 24px;
  padding: 8px 0;
}
.auditlogentry-errorinfo .ant-row>div {
  min-height: 24px;
  padding: 8px 0;
}


.coursecard {
  font-family: Montserrat, 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  background-color: white;
  line-height: 1.4;
  border-left-width: 4px;
  min-height: 50px;
  max-width: 850px;
  padding: 0;
  margin: 0 0 21px 0;
  box-shadow: 0 2px 7px 1px rgba(0,0,0,0.09);
}
.coursecard h1 {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
}
.coursecard-titlebar {
  height: 45px;
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.1);
  padding: 0;
  display: flex;
  flex-direction: row;
}
.coursecard-titlecontainer {
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
}
.coursecard-titlecontainer-expandable {
  cursor: pointer;
}
.coursecard-titlecontainer:not(.coursecard-titlecontainer-expandable) {
  padding-left: 16px;
}
.coursecard-title {
  display: flex;
  align-items: center;
  flex-basis: 100%;
  font-weight: 600;
  font-size: 14px;
  margin-left: 1px;
}
.coursecard-title input {
  /*width: 710px;*/
  margin-left: -3px;
  width: calc(100% + 3px);
  box-sizing: border-box;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}
.coursecard-expiresat-label {
  display: flex !important;
  align-items: center;
}
.coursecard-summaryarea .ant-row>div {
  min-height: 24px;
  padding: 8px 0;
}
.coursecard-expandedarea {
  padding-top: 12px;
}
.coursecard-expandedarea .ant-row>div {
  padding: 8px 0;
}
.coursecard-subtitle {
  font-weight: bold;
  margin-bottom: 8px;
  word-break: break-word;
}
.coursecard-body {
  padding: 16px;
  box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1);
}
.coursecard-body-firstline {}
.coursecard-body-secondline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.coursecard-bottombar {
  padding: 0 16px 0 16px;
  background-color: #f0f2f5;
  height: 45px;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.coursecard-teachingperiod {
  padding-left: 24px;
  display: inline-block;
}

.coursecard-expandbutton, .coursecard-title .editable-field-editbutton {
  display: flex;
  width: 45px;
  min-width: 45px;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.coursecard-overflowbuttoncontainer {
  display: flex;
  width: 45px;
  min-width: 45px;
}

.coursecard-overflowbutton {
  width: 45px;
  height: 45px;

  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;

  cursor: pointer;
}

.coursecard-expandedarea-sectionheading {
  font-weight: bolder;
}

.coursecard-expandedarea-coursesectionitem {
  margin-bottom: 8px;
}
.coursecard-expandedarea-coursesectionitem-name {

}
.coursecard-expandedarea-coursesectionitem-sisid {
  color: gray;
}

.coursecard-state-awaiting_copy {
  border-left-color: #FFB000;
}
.coursecard-state-awaiting_review {
  border-left-color: #DC267F;
}
.coursecard-state-released {
  border-left-color: #785EF0;
}
.coursecard-state-available {
  border-left-color: #277500;
}
.coursecard-state-completed {
  border-left-color: #a3a3a3;
}
.coursecard-state-deleted {
  border-left-color: #363636;
}
.coursecard-statetextcolor-awaiting_copy {
  color: #FFB000;
}
.coursecard-statetextcolor-awaiting_review {
  color: #DC267F;
}
.coursecard-statetextcolor-released {
  color: #785EF0;
}
.coursecard-statetextcolor-available {
  color: #277500;
}
.coursecard-statetextcolor-completed {
  color: #a3a3a3;
}
.coursecard-statetextcolor-deleted {
  color: #363636;
}

.coursecard-copyinprogress,
.coursecard-copyinprogress .ant-spin {
  color: black;
}

.coursecard-teacher {
  display: inline-block;
}
.coursecard-teacher:not(:last-child):after {
  content: ",";
  margin-right: 0.3em;
}
.coursecard-teacher-tooltip {
  display: inline-block;
  cursor: pointer;
  color: #1890ff;
}

.coursecard-actionbuttoncontainer {
  text-align: end;
}

.coursecard-body .ant-list-item-meta-description {
  line-break: anywhere;
}

.coursecard-title.editable-field {
  max-width: 100%;
}

.coursecard-title .editable-field-display {
  display: block;
  max-width: calc(100% - 45px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.coursecard-title .editable-field-editing-components {
  width: 100%;
}
.coursecard-title .input-wrapper {
  width: 100%;
  margin-top: -1px;
}
.editable-field {
  display: inline-flex;
  align-items: center;
}

.editable-field-display {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.editable-field .ant-spin {
  margin-left: 8px;
  visibility: hidden;
}

.editable-field-saving .ant-spin {
  visibility: visible;
}

.input-wrapper {
  position: relative;
}

.editable-input {
  border: 1px solid transparent;
  padding-right: 24px;
}

.editable-input:focus {
  border: 1px solid lightgrey;
  outline: none;
}

.enter-icon {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.editable-field-editbutton {
  cursor: pointer;
}

.editable-field-display-components, .editable-field-editing-components {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.editable-field.editable-field-editing .editable-field-display-components {
  display: none;
}
.editable-field:not(.editable-field-editing) .editable-field-editing-components {
  display: none;
}
#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.app-layout {
  min-height: 100vh;
}

.content-layout .site-layout-background {
  background: #fff;
}

.ant-layout-content {
  margin: 16px;
  max-width: 800px;
  width: 100%;
}

.ant-layout-footer {
  text-align: center;
}
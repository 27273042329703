.coursecard-comments {
    padding: 16px;
}

.comment-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
}

.new-comment-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.show-all-comments-wrapper {
    margin-bottom: 6px;
}

.edit-comment-wrapper {
    margin-bottom: 6px;
    margin-top: 12px;
}

.new-comment-wrapper .ant-form-item {
    margin-bottom: 0;
}

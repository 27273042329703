.expandable-element {
    position: relative;
}

.expandable-element-content-collapsed {
    overflow: hidden;
    background: linear-gradient(to bottom, black 50%, rgba(0, 0, 0, 0)); /* Define your gradient colors */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.expandable-element-expand-btn {
    margin-top: 10px;
}
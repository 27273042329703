.auditlogs {
    width: 100%;
}

.auditlogs-actions {
    display: flex;
    justify-content: flex-end;
}

.auditlogs-results {
    width: 100%;
}
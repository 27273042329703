/* TODO: Prefix CSS names */
.staff-enrolment .page {
    max-width: 818px;
}

.staff-enrolment .title {
    color: #FFFFFF;
    background-color: #2D2D2D;
    /*font-family: Montserrat;*/
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    padding: 0 24px;
    height: 56px;
    line-height: 56px;
}

.staff-enrolment .role {
    color: #202020;
    /*font-family: Montserrat;*/
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    /*background-color: #628CFF;*/
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.staff-enrolment .ant-list-header {
    padding-top: 0px;
}

.staff-enrolment .ant-list-item-meta-avatar {
    margin-left: 12px;
}

.staff-enrolment .ant-list-item-meta-title {
    margin: 0;
}

.staff-enrolment .ant-list-split .ant-list-header {
    border-bottom: none;
}
.staff-enrolment .ant-list-split .ant-list-item {
    border-bottom: none;
}

.staff-enrolment .ant-empty-image svg {
    margin-top: 1em;
}

/*.ant-spin {*/
/*    margin: 1.5em;*/
/*}*/



.staff-enrolment .edit-btn {
    font-size: 18px;
}

.staff-enrolment .delete-btn {
    font-size: 18px;
}

.ant-popconfirm.staff-enrolment-delete-confirm {
    width: 300px;
}